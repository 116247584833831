<template>
  <div class="content">
    <PageHeader :title="$t('flows.header')" subtitle="Lista de Flow" />
    <div class="page-content container-fluid">
      <div class="row">
        <div class="col-8 mx-auto mb-5">
          <div class="form-wrapper">
            <form class="form-group col px-0 mb-0">
              <div class="input-group">
                <input
                  placeholder="Digite o nome do Flow"
                  type="text"
                  class="form-control"
                  v-model="nameFlow"
                />
                <div class="input-group-prepend">
                  <button @click="search(1)" type="button" class="btn icon_btn m-0">
                    <span class="material-symbols-outlined"> search </span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <!-- <div class="row">
        <div class="col-6 mx-auto mb-5">
          <div class="form-wrapper">
            <form @submit.prevent="search(1)" class="form-group col px-0 mb-0">
              <div class="relative">
                <input
                  placeholder=""
                  type="text"
                  class="form-control"
                  ref="searchInputN"
                />
                <button type="submit" class="btn icon_btn m-0 btn-search">
                  <span class="material-symbols-outlined"> search </span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div> -->
      <div class="container container-add">
        <div class="row">
          <div class="col-12">
            <div class="card card-add-list">
              <div id="close-dialog" class="btn-close" @click="closeDialog"></div>
              <div class="card-body">
                <div class="col-8 d-flex justify-content-center flex-column">
                  <p class="card-text" v-html="$t('flows.description')"></p>
                  <div class="w-100"></div>
                  <div>
                    <button
                      v-b-toggle.sidebar-right-flow
                      class="btn btn-yup btn-add to-left"
                    >
                      {{ $t("flows.create") }}
                    </button>
                    <button
                      v-b-toggle.sidebar-right-flow
                      class="btn btn-yup btn-add-template to-left"
                    >
                      {{ $t("flows.templates") }}
                    </button>
                  </div>
                </div>
                <div class="col d-flex justify-content-center align-items-center">
                  <div
                    class="star-1-flow"
                    style="background-image: url('assets/img/star_flow_1.png')"
                  >
                    <div
                      class="star-2-flow"
                      style="background-image: url('assets/img/star_flow_2.png')"
                    >
                      <img
                        v-b-toggle.sidebar-right-flow
                        src="@/assets/blue-success-feedback.gif"
                        alt="Add"
                        width="167"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Title -->
      <div class="row relative">
        <div class="col-12 col-sm">
          <span
            class="title-list c-pointer"
            @click="(leftLine = 1), changeFlowListActive('active')"
          >
            <span class="material-symbols-outlined title-list-icon">bolt</span>
            <strong class="title-list-text"> {{ $t("flows.active") }}</strong>
            <div style="clear: both"></div>
          </span>
        </div>
        <div class="col-12 col-sm">
          <span
            class="title-list c-pointer"
            @click="(leftLine = 28.5), changeFlowListInactive('inactive')"
          >
            <span class="material-symbols-outlined title-list-icon">warning</span>
            <strong class="title-list-text"> {{ $t("flows.inactive") }}</strong>
            <div style="clear: both"></div>
          </span>
        </div>
        <div class="col-12 col-sm">
          <span
            class="title-list c-pointer"
            @click="(leftLine = 58), changeFlowListDraft('draft')"
          >
            <span class="material-symbols-outlined title-list-icon">build</span>
            <strong class="title-list-text"> {{ $t("flows.draft") }}</strong>
            <div style="clear: both"></div>
          </span>
        </div>
        <div class="col-12 col-sm-2">
          <button v-b-toggle.sidebar-right-flow class="btn btn-yup btn-add to-left">
            {{ $t("flows.create") }}
          </button>
        </div>
        <hr class="purple" />
        <div class="title-list-line" :style="`left: ${leftLine}%`"></div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <div class="all-check-box">
            <span class="to-left check-info">
              <div class="control-group opt-2 to-left" style="margin-right: 5px">
                <label class="control control-checkbox" :for="'template-checka-all'">
                  <input
                    v-model="selectAllFlows"
                    type="checkbox"
                    class="custom-control-input"
                    :id="'template-checka-all'"
                  />
                  <div class="control_indicator"></div>
                </label>
              </div>
              Tudo
            </span>
            <a
              href="javascript:void(0)"
              class="to-left check-all-info c-pointer"
              style="margin-left: 15px; color: #9ca7b0"
              @click="activeAll"
              v-if="
                selectedFlows.length && tab.inactiveTab === true && tab.draftTab === false
              "
            >
              <span class="material-symbols-outlined to-left">lock_open</span>
              <span class="to-left">Ativar {{ selectedFlows.length }}</span>
            </a>
            <a
              href="javascript:void(0)"
              class="to-left check-all-info c-pointer"
              style="margin-left: 15px; color: #9ca7b0"
              @click="inactiveAll"
              v-if="
                selectedFlows.length &&
                tab.inactiveTab === false &&
                tab.draftTab === false
              "
            >
              <span class="material-symbols-outlined to-left">lock</span>
              <span class="to-left">Desativar {{ selectedFlows.length }}</span>
            </a>
            <a
              href="javascript:void(0)"
              class="to-left check-all-info c-pointer"
              style="margin-left: 15px; color: #9ca7b0"
              @click="deleteAll"
              v-if="
                (tab.inactiveTab === true || tab.draftTab === true) &&
                selectedFlows.length
              "
            >
              <span class="material-symbols-outlined to-left">delete_forever</span>
              <span class="to-left"
                >Excluir itens selecionados {{ selectedFlows.length }}</span
              >
            </a>
            <div style="clear: both"></div>
          </div>
          <br />
        </div>
      </div>
      <div class="text-center py-5" v-if="!fetched">
        <img src="assets/img/loading.gif" />
      </div>
      <!--/Title -->
      <div class="row">
        <div class="col-lg-4 col-md-6 col-sm-12" v-for="(flow, i) in flows" :key="i">
          <div class="card-flow-template">
            <div class="card-body relative" style="height: 250px !important">
              <div>
                <div class="control-group opt-2 flow-channel-item-check">
                  <label class="control control-checkbox" :for="'flow-check' + i">
                    <input
                      type="checkbox"
                      class="custom-control-input"
                      :id="'flow-check' + i"
                      :value="flow"
                      v-model="selectedFlows"
                    />
                    <div class="control_indicator"></div>
                  </label>
                </div>
                <div
                  class="element-flow-item-title"
                  style="margin-left: 10px; float: left"
                >
                  {{ flow.name }}
                </div>
                <span
                  class="material-symbols-outlined flow-card-title-info"
                  v-if="flow.description"
                  v-b-popover.hover.top="`${flow.description}`"
                >
                  info
                </span>
                <div class="flow-channel-item-menu relative">
                  <dropdown>
                    <template v-slot:text>
                      <span class="material-symbols-outlined title"> more_horiz </span>
                    </template>
                    <template #items>
                      <a class="dropdown-item" @click="edit(flow.id)">
                        <span class="material-symbols-outlined to-left">edit</span>
                        <span class="to-left left15"> Editar</span>
                        <div style="clear: both"></div>
                      </a>
                      <a class="dropdown-item" @click="remove(flow.id)">
                        <span class="material-symbols-outlined to-left"
                          >delete_forever</span
                        >
                        <span class="to-left left15"> Excluir</span>
                        <div style="clear: both"></div>
                      </a>
                      <a
                        @click="changeToggle(flow.id, true), (flow.active = !flow.active)"
                        class="dropdown-item"
                        v-if="!flow.active"
                      >
                        <span class="material-symbols-outlined to-left">lock_open</span>
                        <span class="to-left top2 left15"> Ativar</span>
                        <div style="clear: both"></div>
                      </a>
                      <a
                        @click="
                          changeToggle(flow.id, false), (flow.active = !flow.active)
                        "
                        class="dropdown-item"
                        v-if="flow.active"
                      >
                        <span class="material-symbols-outlined to-left">lock</span>
                        <span class="to-left top2 left15"> Inativar</span>
                        <div style="clear: both"></div>
                      </a>
                      <a
                        @click="(newFlow = flow), (isClone = true)"
                        class="dropdown-item"
                        v-b-toggle.sidebar-right-flow
                      >
                        <span class="material-symbols-outlined to-left">file_copy</span>
                        <span class="to-left top2 left15"> Clonar</span>
                        <div style="clear: both"></div>
                      </a>
                    </template>
                  </dropdown>
                </div>
                <div style="clear: both"></div>
              </div>
              <div class="element-flow-item-card">
                <div class="flow-channel-item">
                  <img
                    src="assets/img/icons/flow/WhatsApp.svg"
                    class="c-pointer"
                    v-if="flow.channel_id === whatsId"
                  />
                  <img
                    src="assets/img/icons/flow/Messenger.svg"
                    class="c-pointer"
                    v-else-if="flow.channel_id === messengerId"
                  />
                  <img
                    src="assets/img/icons/flow/instagram.svg"
                    class="c-pointer"
                    v-else-if="flow.channel_id === instagramId"
                  />
                  <img
                    src="assets/img/icons/flow/whats_meta.png"
                    class="c-pointer"
                    v-else-if="flow.channel_id === whatsIdLive"
                  />
                  <img
                    src="assets/img/icons/flow/telegram.svg"
                    class="c-pointer"
                    v-else-if="flow.channel_id === telegramId"
                  />
                  <img
                    src="assets/img/icons/flow/rcs.svg"
                    class="c-pointer"
                    v-else-if="flow.channel_id === rcsId"
                  />
                  <img
                    src="assets/img/icons/flow/chat.svg"
                    class="c-pointer"
                    v-else-if="flow.channel_id === widgetId"
                  />
                  <img
                    src="assets/img/icons/flow/business_messages.svg"
                    class="c-pointer"
                    v-else-if="flow.channel_id === businessId"
                  />
                </div>
                <div class="flow-channel-item-line"></div>
                <div class="flow-channel-item">
                  <img src="assets/img/icons/flow/omni.png" class="c-pointer" />
                </div>
                <div style="clear: both"></div>
              </div>
              <span
                class="span-type-flow"
                v-if="flow.channel_id !== whatsId && flow.identification_name"
                >{{ flow.identification_name }}</span
              >
              <span
                v-if="flow.type_trigger == 'WEBHOOK'"
                style="font-size: 13px; margin-bottom: 5px; display: block"
              >
                <i class="fas fa-wrench"></i> Webhook
              </span>
              <span
                v-if="flow.type_trigger == 'CHANNEL'"
                style="font-size: 13px; margin-bottom: 5px; display: block"
              >
                <i class="fas fa-comment"></i> Canal
              </span>
              <span class="refresh-date-item">
                <img
                  src="assets/img/icons/support_refresh.png"
                  style="width: 15px; height: 20px"
                />
                {{ flow.last_update | relativeDate }}
              </span>
              <div style="clear: both"></div>
            </div>
          </div>
        </div>
        <Pagination :lastPage="pages" @change="fetch" />
        <div class="col-sm-12 text-center" v-if="flows.length == 0 && fetched">
          <span class="material-icons-outlined icon-no-item">account_tree</span>
          <span> Nenhum fluxo encontrado </span>
        </div>
      </div>
      <div><br /></div>
      <b-sidebar
        id="sidebar-right-flow"
        :title="$t('catalog.list.edit')"
        no-header
        right
        backdrop
        shadow
      >
        <div class="col sidebar-header">
          <p>{{ $t("flows.create") }}</p>
          <span
            v-b-toggle.sidebar-right-flow
            class="material-symbols-outlined sidebar-close"
            >close</span
          >
        </div>
        <div class="col desc-column">
          <p>{{ $t("flows.description") }}</p>
        </div>
        <form class="add-prod-form col mb-0">
          <!-- Name -->
          <div class="input-group">
            <label for=""
              >{{ $tc("app.name", 1) }}
              <span class="material-symbols-outlined"> info </span></label
            >
            <input
              placeholder=""
              type="text"
              class="form-control border-radius-5"
              v-model="newFlow.name"
            />
          </div>

          <!-- Description -->
          <div class="input-group">
            <label for=""
              >{{ $t("app.description") }}
              <span class="material-symbols-outlined"> info </span></label
            >
            <textarea
              placeholder=""
              type="text"
              class="form-control border-radius-5"
              v-model="newFlow.description"
            ></textarea>
          </div>
          <div class="input-group">
            <div class="trigger-type">
              <h3>Tipo de gatilho</h3>
              <span class="material-symbols-outlined"> info </span>
            </div>
          </div>
          <div class="input-group">
            <div
              class="trigger-type-button trigger-flow relative c-pointer"
              @click="changeTrigger('WEBHOOK')"
              :class="{ 'trigger-flow-active': newFlow.type_trigger == 'WEBHOOK' }"
            >
              <img
                v-if="newFlow.type_trigger == 'WEBHOOK'"
                src="assets/img/icons/flow/check_circle.svg"
                class="c-pointer trigger-flow-active-icon"
              />
              <div class="trigger-type-icon">
                <img
                  v-if="newFlow.type_trigger == 'WEBHOOK'"
                  src="assets/img/webhook_active.svg"
                />
                <img v-else src="assets/img/webhook.svg" />
              </div>
              <div class="trigger-type-description">
                <div class="trigger-type-titles">
                  <h3>Webhook</h3>
                  <p>Integre e dispare o flow através dos nossos Webhook.</p>
                </div>
              </div>
            </div>
          </div>
          <div class="input-group">
            <div
              class="trigger-type-button trigger-flow c-pointer"
              @click="changeTrigger('CHANNEL')"
              :class="{ 'trigger-flow-active': newFlow.type_trigger == 'CHANNEL' }"
            >
              <img
                v-if="newFlow.type_trigger == 'CHANNEL'"
                src="assets/img/icons/flow/check_circle.svg"
                class="c-pointer trigger-flow-active-icon"
              />
              <div class="trigger-type-icon">
                <img
                  v-if="newFlow.type_trigger == 'CHANNEL'"
                  src="assets/img/question_answer_active.svg"
                />
                <img v-else src="assets/img/question_answer.svg" />
              </div>
              <div class="trigger-type-description">
                <div class="trigger-type-titles">
                  <h3>Canal</h3>
                  <p>Dispare quando o cliente enviar uma mensagem através dos canais.</p>
                </div>
              </div>
            </div>
          </div>
          <div class="input-group">
            <label class="info-channel">Selecione um canal de disparo</label>
          </div>
          <div class="input-group" v-if="newFlow.type_trigger != 'WEBHOOK'">
            <div class="trigger-type-button trigger-type-button-channel">
              <div class="trigger-type-icon-channel">
                <img src="assets/img/icons/flow/instagram.svg" />
              </div>
              <div class="trigger-type-description-channel">
                <div class="trigger-type-titles">
                  <h3>Instagram</h3>
                  <p>Envie pesquisas através do instagram</p>
                </div>
              </div>
              <div class="trigger-type-checkbox">
                <div class="control-group opt-2 toggle-check" style="margin-top: 10px">
                  <label class="control control-checkbox">
                    <input
                      type="checkbox"
                      name="notifications-opt"
                      v-model="isInstagram"
                      @change="changeChannel(instagramId, 'instagram', 'instagram.svg')"
                    />
                    <div class="control_indicator" style="margin-bottom: 0"></div>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="input-group">
            <div class="trigger-type-button trigger-type-button-channel">
              <div class="trigger-type-icon-channel">
                <img src="assets/img/icons/flow/telegram.svg" />
              </div>
              <div class="trigger-type-description-channel">
                <div class="trigger-type-titles">
                  <h3>Telegram</h3>
                  <p>Envie pesquisas através do telegram</p>
                </div>
              </div>
              <div class="trigger-type-checkbox">
                <div class="control-group opt-2 toggle-check" style="margin-top: 10px">
                  <label class="control control-checkbox">
                    <input
                      type="checkbox"
                      name="notifications-opt"
                      v-model="isTelegram"
                      @change="changeChannel(telegramId, 'telegram', 'telegram.svg')"
                    />
                    <div class="control_indicator" style="margin-bottom: 0"></div>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="input-group" v-if="newFlow.type_trigger != 'WEBHOOK'">
            <div class="trigger-type-button trigger-type-button-channel">
              <div class="trigger-type-icon-channel">
                <img src="assets/img/icons/flow/rcs.svg" />
              </div>
              <div class="trigger-type-description-channel">
                <div class="trigger-type-titles">
                  <h3>RCS</h3>
                  <p>Envie pesquisas através do rcs</p>
                </div>
              </div>
              <div class="trigger-type-checkbox">
                <div class="control-group opt-2 toggle-check" style="margin-top: 10px">
                  <label class="control control-checkbox">
                    <input
                      type="checkbox"
                      name="notifications-opt"
                      v-model="isRCS"
                      @change="changeChannel(rcsId, 'rcs', 'rcs.svg')"
                    />
                    <div class="control_indicator" style="margin-bottom: 0"></div>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="input-group" v-if="newFlow.type_trigger != 'WEBHOOK'">
            <div class="trigger-type-button trigger-type-button-channel">
              <div class="trigger-type-icon-channel">
                <img src="assets/img/icons/flow/Messenger.svg" />
              </div>
              <div class="trigger-type-description-channel">
                <div class="trigger-type-titles">
                  <h3>Messenger</h3>
                  <p>Envie pesquisas através do Messenger</p>
                </div>
              </div>
              <div class="trigger-type-checkbox">
                <div class="control-group opt-2 toggle-check" style="margin-top: 10px">
                  <label class="control control-checkbox">
                    <input
                      type="checkbox"
                      name="notifications-opt"
                      v-model="isMessenger"
                      @change="changeChannel(messengerId, 'mesenger', 'messenger.svg')"
                    />
                    <div class="control_indicator" style="margin-bottom: 0"></div>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="input-group">
            <div class="trigger-type-button trigger-type-button-channel">
              <div class="trigger-type-icon-channel">
                <img src="assets/img/icons/flow/whats_meta.png" />
              </div>
              <div class="trigger-type-description-channel">
                <div class="trigger-type-titles">
                  <h3>WhatsApp Meta</h3>
                  <p>Envie pesquisas através do WhatsApp Meta</p>
                </div>
              </div>
              <div class="trigger-type-checkbox">
                <div class="control-group opt-2 toggle-check" style="margin-top: 10px">
                  <label class="control control-checkbox">
                    <input
                      type="checkbox"
                      name="notifications-opt"
                      v-model="isWhatsLive"
                      @click="changeChannel(whatsIdLive, 'whats_meta', 'whats_meta.png')"
                    />
                    <div class="control_indicator" style="margin-bottom: 0"></div>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="input-group">
            <div class="trigger-type-button trigger-type-button-channel">
              <div class="trigger-type-icon-channel">
                <img src="assets/img/icons/flow/WhatsApp.svg" />
              </div>
              <div class="trigger-type-description-channel">
                <div class="trigger-type-titles">
                  <h3>WhatsApp</h3>
                  <p>Envie pesquisas através do WhatsApp</p>
                </div>
              </div>
              <div class="trigger-type-checkbox">
                <div class="control-group opt-2 toggle-check" style="margin-top: 10px">
                  <label class="control control-checkbox">
                    <input
                      type="checkbox"
                      name="notifications-opt"
                      v-model="isWhatsApp"
                      @change="changeChannel(whatsId, 'whatsapp', 'whatsapp.svg')"
                    />
                    <div class="control_indicator" style="margin-bottom: 0"></div>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="input-group" v-if="newFlow.type_trigger != 'WEBHOOK'">
            <div class="trigger-type-button trigger-type-button-channel">
              <div class="trigger-type-icon-channel">
                <img src="assets/img/icons/flow/chat.svg" />
              </div>
              <div class="trigger-type-description-channel">
                <div class="trigger-type-titles">
                  <h3>Widget</h3>
                  <p>Envie pesquisas através do Widget</p>
                </div>
              </div>
              <div class="trigger-type-checkbox">
                <div class="control-group opt-2 toggle-check" style="margin-top: 10px">
                  <label class="control control-checkbox">
                    <input
                      type="checkbox"
                      name="notifications-opt"
                      v-model="isWidget"
                      @change="changeChannel(widgetId, 'widget', 'chat.svg')"
                    />
                    <div class="control_indicator" style="margin-bottom: 0"></div>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="input-group" v-if="newFlow.type_trigger != 'WEBHOOK'">
            <div class="trigger-type-button trigger-type-button-channel">
              <div class="trigger-type-icon-channel">
                <img src="assets/img/icons/flow/business_messages.svg" />
              </div>
              <div class="trigger-type-description-channel">
                <div class="trigger-type-titles">
                  <h3>Business Messages</h3>
                  <p>Envie pesquisas através do business messages</p>
                </div>
              </div>
              <div class="trigger-type-checkbox">
                <div class="control-group opt-2 toggle-check" style="margin-top: 10px">
                  <label class="control control-checkbox">
                    <input
                      type="checkbox"
                      name="notifications-opt"
                      v-model="isBusiness"
                      @change="
                        changeChannel(businessId, 'business', 'business_messages.svg')
                      "
                    />
                    <div class="control_indicator" style="margin-bottom: 0"></div>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="input-group" v-if="this.pagesChannel.length > 0">
            <label class="info-channel">Opcões cadastradas:</label>
          </div>
          <div class="input-group" v-for="p in this.pagesChannel" :key="p.index">
            <div class="trigger-type-button trigger-type-button-channel">
              <div
                class="trigger-type-description-channel trigger-type-description-channel-item"
              >
                <div class="trigger-type-titles">
                  <p class="trigger-type-titles-item">
                    {{ p.identification_name ? p.identification_name : p.external_id }}
                  </p>
                </div>
              </div>
              <div class="trigger-type-checkbox">
                <div class="control-group opt-2 toggle-check" style="margin-top: 10px">
                  <label class="control control-checkbox">
                    <input
                      type="checkbox"
                      name="notifications-opt"
                      @change="changePage(p.external_id)"
                    />
                    <div class="control_indicator" style="margin-bottom: 0"></div>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div
            class="input-group"
            v-if="
              (newFlow.channel_id == whatsId || newFlow.channel_id == whatsIdLive) &&
              newFlow.type_trigger == 'WEBHOOK'
            "
          >
            <label class="info-channel">Nome dos campos:</label>
          </div>
          <div
            class="input-group"
            v-if="
              (newFlow.channel_id == whatsId || newFlow.channel_id == whatsIdLive) &&
              newFlow.type_trigger == 'WEBHOOK'
            "
          >
            <div class="row">
              <div class="col-sm-12" style="padding-bottom: 10px">
                <input
                  placeholder="Campo de nome"
                  class="form-control"
                  v-model="newFlow.client_name_field"
                />
              </div>
              <div class="col-sm-12" style="padding-bottom: 10px">
                <input
                  placeholder="Campo de email"
                  class="form-control"
                  v-model="newFlow.client_email_field"
                />
              </div>
              <div class="col-sm-12">
                <input
                  placeholder="Campo de telefone"
                  class="form-control"
                  v-model="newFlow.client_phone_field"
                />
              </div>
            </div>
          </div>
          <div class="input-group text-right flex-c">
            <button
              type="button"
              v-b-toggle.sidebar-right-flow
              class="btn btn-yup btn-add align-r"
              @click="createFlow"
            >
              {{ $t("flows.save") }}
            </button>
          </div>
        </form>
      </b-sidebar>
    </div>
  </div>
</template>

<script>
import 'bootstrap-vue/dist/bootstrap-vue.css';
// @ is an alias to /src
import PageHeader from '@/components/rebranding/PageHeader.vue';
import Swal from 'sweetalert2';
import axios from '@/plugins/axios';
import ChannelService from '@/services/channel.service';
import Dropdown from '@/components/Dropdown.vue';
import Tab from '@/directives/Tab';
import $ from 'jquery';
import Pagination from '@/components/Pagination.vue';

const webApi = axios();

export default {
  name: 'ListFlows',
  components: {
    PageHeader,
    Dropdown,
    Pagination,
  },
  directives: {
    Tab,
  },
  data() {
    return {
      leftLine: 1,
      fetched: false,
      nameFlow: '',
      tabLeft: '10px',
      tabActive: 'active',
      tab: {
        inactiveTab: false,
        draftTab: false,
      },
      flowLoading: false,
      instagramId: process.env.VUE_APP_INSTAGRAM_ID,
      isInstagram: false,
      whatsId: process.env.VUE_APP_WHATS_ID,
      isWhatsApp: false,
      whatsIdLive: process.env.VUE_APP_WHATS_META,
      isWhatsLive: false,
      messengerId: process.env.VUE_APP_MESSENGER_ID,
      isMessenger: false,
      telegramId: process.env.VUE_APP_TELEGRAM_ID,
      isTelegram: false,
      widgetId: process.env.VUE_APP_WIDGET_ID,
      isWidget: false,
      rcsId: process.env.VUE_APP_RCS_ID,
      isRCS: false,
      businessId: process.env.VUE_APP_BUSINESS_ID,
      isBusiness: false,
      flows: [],
      flowsActive: [],
      flowsInactive: [],
      flowsDraft: [],
      form: {
        q: '',
        page: 1,
      },
      currentType: 'active',
      page: 1,
      pages: 1,
      isClone: false,
      newFlow: {
        name: '',
        description: '',
        channel_id: '',
        type_trigger: '',
        icon_name: '',
        account_channel_external_id: '',
        version: 3,
      },
      selectedFlows: [],
      Toast: {},
      pagesChannel: [],
      whatsNumbers: [],
    };
  },
  computed: {
    selectAllFlows: {
      get() {
        if (this.flows) {
          return this.selectedFlows.length === this.flows.length;
        }
        return false;
      },
      set(value) {
        const selected = [];

        if (value) {
          this.flows.forEach((flow) => {
            selected.push(flow);
          });
        }

        this.selectedFlows = selected;
      },
    },
  },
  created() {
    this.fetch(1);
    this.Toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      onOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
      },
    });
    this.getWhatsAccount();
  },
  methods: {
    changeTabLeft(left) {
      this.tabLeft = left;
    },
    getWhatsAccount(channel) {
      this.flowLoading = true;
      this.newFlow.channel_id = channel;
      this.whatsNumbers = [];
      webApi.get('whatsapp/new/numbers').then(
        (response) => {
          this.flowLoading = false;
          response.data.forEach((data) => {
            if (data.deleted === false) {
              this.whatsNumbers.push(data);
            }
          });
        },
        (error) => {
          this.content = error;
          this.flowLoading = false;
        },
      );
    },
    changeToggle(id, active) {
      this.tabActive = active ? 'active' : 'inactive';
      this.flows = active ? this.flowsActive : this.flowsInactive;
      this.tabLeft = '10px';
      this.$forceUpdate();
      webApi
        .put(`flow/${id}`, {
          active,
        })
        .then(
          () => {
            this.flows = [];
            this.$toast.show({
              title: 'Successo',
              content: 'Flow atualizado',
              type: 'success',
            });
            this.fetch(1);
            this.$forceUpdate();
          },
          (error) => {
            console.log(error);
            this.$toast.show({
              title: 'Erro',
              content:
                'Erro interno, por favor tente novamente mais tarde ou contact o suporte para ajuda.',
              type: 'error',
            });
          },
        );
    },
    inactiveAll() {
      Swal.fire({
        title: 'Inativar fluxos selecionados',
        text: 'Tem certeza que gostaria de inativar os fluxos?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('generic-str.yes'),
        cancelButtonText: this.$t('app.cancel'),
      }).then((result) => {
        if (result.value) {
          const flows = [];
          this.selectedFlows.forEach((item) => {
            flows.push(item.id);
          });

          webApi.put('flows/active/false', { flows }).then(
            (response) => {
              this.fetch(this.form.page);
              Swal.fire(
                `${this.$t('flows.inactive-success')}!`,
                'Seus fluxos foram inativados.',
                'success',
              );
              this.selectedFlows = [];
            },
            (error) => {
              this.$toast.show({
                title: `${this.$t('sms.infos.filters.status.lbl-error')} ${error.code}`,
                content: error.response.data.message,
                type: 'danger',
              });
            },
          );
        }
      });
    },
    activeAll() {
      Swal.fire({
        title: 'Ativar fluxos selecionados',
        text: 'Tem certeza que gostaria de ativar os fluxos?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('generic-str.yes'),
        cancelButtonText: this.$t('app.cancel'),
      }).then((result) => {
        if (result.value) {
          const flows = [];
          this.selectedFlows.forEach((item) => {
            flows.push(item.id);
          });

          webApi.put('flows/active/true', { flows }).then(
            (response) => {
              this.fetch(this.form.page);
              $('.card-tabs .nav-item .nav-link').removeClass('active');
              $('.card-tabs .nav-item:first-of-type .nav-link').addClass('active');
              Swal.fire(
                `${this.$t('flows.active-success')}!`,
                'Seus fluxos foram ativados.',
                'success',
              );
              this.selectedFlows = [];
            },
            (error) => {
              this.$toast.show({
                title: `${this.$t('sms.infos.filters.status.lbl-error')} ${error.code}`,
                content: error.response.data.message,
                type: 'danger',
              });
            },
          );
        }
      });
    },
    changePage(page) {
      this.newFlow.account_channel_external_id = page;
      this.$forceUpdate();
    },
    changeTrigger(trigger) {
      if (
        this.newFlow.channel_id !== this.widgetId &&
        this.newFlow.channel_id !== this.rcsId &&
        this.newFlow.channel_id !== this.businessId
      ) {
        this.newFlow.account_channel_external_id = null;
      }
      this.newFlow.type_trigger = trigger;
      this.$forceUpdate();
    },
    changeChannel(channel, name, icon) {
      this.isInstagram = false;
      this.isWhatsApp = false;
      this.isWhatsLive = false;
      this.isMessenger = false;
      this.isTelegram = false;
      this.isWidget = false;
      this.isRCS = false;
      this.isBusiness = false;
      switch (name) {
        case 'instagram':
          this.isInstagram = !this.isInstagram;
          break;
        case 'whatsapp':
          this.isWhatsApp = !this.isWhatsApp;
          break;
        case 'telegram':
          this.isTelegram = !this.isTelegram;
          break;
        case 'widget':
          this.isWidget = !this.isWidget;
          break;
        case 'rcs':
          this.isRCS = !this.isRCS;
          break;
        case 'mesenger':
          this.isMessenger = !this.isMessenger;
          break;
        case 'whats_meta':
          this.isWhatsLive = !this.isWhatsLive;
          break;
        case 'business':
          this.isBusiness = !this.isBusiness;
          break;
        default:
          break;
      }

      this.newFlow.channel_id = channel;
      this.newFlow.icon_name = icon;
      if (name === 'instagram') {
        this.getInstagram();
      } else if (channel !== this.widgetId) {
        this.getAccountChannel(channel);
      }
      if (
        channel === this.widgetId ||
        channel === this.rcsId ||
        channel === this.businessId
      ) {
        this.newFlow.account_channel_external_id = this.$store.state.account.id;
      }
      this.$forceUpdate();
    },
    search(page) {
      this.form.page = page;
      this.fetched = false;

      this.flowsActive = [];
      this.flowsInactive = [];
      this.flowsDraft = [];
      this.flows = [];
      this.pages = 1;

      if (this.nameFlow === '') {
        this.fetch(1);
      } else {
        webApi
          .get(
            `flow/search/${this.nameFlow}?type=${this.currentType}&page=${page}`,
            this.form,
          )
          .then(
            (response) => {
              this.fetched = true;

              const flows = response.data.content || [];

              flows.forEach((flow) => {
                if (flow.active && !flow.template) {
                  this.flowsActive.push(flow);
                } else if (!flow.draft && !flow.template) {
                  this.flowsInactive.push(flow);
                }
                if (flow.draft && !flow.template) {
                  this.flowsDraft.push(flow);
                }
              });

              if (this.currentType === 'active') {
                this.flows = this.flowsActive;
              } else if (this.currentType === 'inactive') {
                this.flows = this.flowsInactive;
              } else if (this.currentType === 'draft') {
                this.flows = this.flowsDraft;
              }

              this.pages = response.data.totalPages;
            },
            (error) => {
              this.content = error;
            },
          );
      }
    },
    changeFlowListActive(type) {
      this.selectedFlows = [];
      this.tab.inactiveTab = false;
      this.tab.draftTab = false;
      this.flows = this.flowsActive;
      this.tabActive = 'active';
      this.currentType = type;
      this.fetch(1);
    },
    changeFlowListInactive(type) {
      this.selectedFlows = [];
      this.tab.inactiveTab = true;
      this.tab.draftTab = false;
      this.flows = this.flowsInactive;
      this.tabActive = 'inactive';
      this.currentType = type;
      this.fetch(1);
    },
    changeFlowListDraft(type) {
      this.selectedFlows = [];
      this.tab.inactiveTab = false;
      this.tab.draftTab = true;
      this.flows = this.flowsDraft;
      this.currentType = type;
      this.fetch(1);
    },
    setType(type) {
      this.currentType = type;
      this.fetch(1);
    },
    fetch(page) {
      this.fetched = false;

      this.flowsActive = [];
      this.flowsInactive = [];
      this.flowsDraft = [];
      this.flows = [];

      this.page = page;

      webApi.get(`flows?type=${this.currentType}&page=${page}`).then(
        (response) => {
          this.fetched = true;

          const flows = response.data.content || [];

          flows.forEach((flow) => {
            if (flow.active && !flow.template) {
              this.flowsActive.push(flow);
            } else if (!flow.draft && !flow.template) {
              this.flowsInactive.push(flow);
            }
            if (flow.draft && !flow.template) {
              this.flowsDraft.push(flow);
            }
          });

          if (this.currentType === 'active') {
            this.flows = this.flowsActive;
          } else if (this.currentType === 'inactive') {
            this.flows = this.flowsInactive;
          } else if (this.currentType === 'draft') {
            this.flows = this.flowsDraft;
          }
          this.pages = response.data.totalPages;
        },
        (error) => {
          this.content = error;
        },
      );
    },
    saveFlow() {
      this.flowLoading = true;
    },
    getInstagram() {
      this.flowLoading = true;
      ChannelService.instagramPage(process.env.VUE_APP_INSTAGRAM_LIVE_ID)
        .then(
          (response) => {
            this.pagesChannel = [];
            response.data.forEach((item) => {
              this.pagesChannel.push({
                identification_name: item.name,
                external_id: item.identification,
              });
            });
            this.flowLoading = false;
          },
          (error) => {
            this.$toast.show({
              title: 'Erro',
              content: error.response.data.message,
              type: 'danger',
            });
            this.flowLoading = false;
          },
        )
        .catch((error) => {
          console.log(error.response);
          this.flowLoading = false;
        });
    },
    getAccountChannel(channel) {
      this.flowLoading = true;
      webApi.get(`flows/account_channel/${channel}`).then(
        (response) => {
          this.pagesChannel = [];
          response.data.forEach((item) => {
            if (item.deleted === 'false') {
              this.pagesChannel.push(item);
            }
          });
          this.flowLoading = false;
        },
        (error) => {
          this.content = error;
          this.flowLoading = false;
        },
      );
    },
    closeDialog() {
      // document.getElementById('close-dialog').addEventListener('click', closeBubble);
      document.querySelectorAll('.container-add').forEach((el) => el.remove());
    },
    createFlow() {
      if (this.newFlow.name === null || this.newFlow.name.length === 0) {
        this.$toast.show({
          type: 'danger',
          title: 'Flow',
          content: 'O nome precisa ser preenchido',
        });
        return;
      }
      if (this.newFlow.channel_id === '') {
        this.$toast.show({
          type: 'danger',
          title: 'Flow',
          content: 'Você vai precisar escolher um canal',
        });
        return;
      }
      if (this.newFlow.type_trigger === '') {
        this.$toast.show({
          type: 'danger',
          title: 'Flow',
          content: 'Você vai precisar escolher a ação',
        });
        return;
      }
      if (this.newFlow.account_channel_external_id === '') {
        this.$toast.show({
          type: 'danger',
          title: 'Flow',
          content: 'Você vai precisar escolher uma página',
        });
        return;
      }
      this.flowLoading = true;
      webApi.post('flow', this.newFlow).then(
        (response) => {
          const id = response.data.id;
          this.$router.push({ name: 'FlowEdit', params: { id } });
          this.flowLoading = false;
        },
        (error) => {
          this.content = error;
          this.flowLoading = false;
          if (error.response.data.message.includes('409 Conflict')) {
            this.$toast.show({
              title: 'Erro de validação',
              content: 'Ops, talvez já exista um flow para esse canal',
              type: 'danger',
            });
          } else {
            this.$toast.show({
              title: `${this.$t('sms.infos.filters.status.lbl-error')} ${error.code}`,
              content: error.message,
              type: 'danger',
            });
          }
        },
      );
    },
    cloneFlow() {
      if (this.newFlow.name === null || this.newFlow.name.length === 0) {
        this.$toast.show({
          type: 'danger',
          title: 'Flow',
          content: 'O nome precisa ser preenchido',
        });
        return;
      }
      if (this.newFlow.channel_id === '') {
        this.$toast.show({
          type: 'danger',
          title: 'Flow',
          content: 'Você vai precisar escolher um canal',
        });
        return;
      }
      if (this.newFlow.type_trigger === '') {
        this.$toast.show({
          type: 'danger',
          title: 'Flow',
          content: 'Você vai precisar escolher a ação',
        });
        return;
      }
      if (this.newFlow.account_channel_external_id === '') {
        this.$toast.show({
          type: 'danger',
          title: 'Flow',
          content: 'Você vai precisar escolher uma página',
        });
        return;
      }
      this.flowLoading = true;
      webApi.post('flow/clone', this.newFlow).then(
        (response) => {
          const id = response.data.id;
          this.$router.push({ name: 'FlowEdit', params: { id } });
          this.flowLoading = false;
        },
        (error) => {
          this.content = error;
          this.flowLoading = false;
        },
      );
    },
    remove(id) {
      Swal.fire({
        title: this.$t('generic-str.r-sure'),
        text: 'O fluxo será excluído permanentemente. Você não poderá reverter isso!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('warnings.btn-y-delete'),
      }).then((result) => {
        if (result.isConfirmed) {
          webApi.delete(`flow/${id}`).then(
            (response) => {
              this.fetch(this.form.page);
              Swal.fire(
                `${this.$t('generic-str.deleted')}!`,
                'Seu fluxo foi excluído.',
                'success',
              );
            },
            (error) => {
              this.content = error;
            },
          );
        }
      });
    },
    create(type) {
      switch (type) {
        case 'import':
          console.warn('Importar');
          break;
        case 'base':
          console.warn('Criar a partir de base');
          break;
        case 'blank':
          this.$router.push({ name: 'FlowCreate' });
          break;
        default:
          console.error('Nada foi selecionado');
      }
    },
    history(id) {
      console.warn(`Historico de ${id}`);
    },
    edit(id) {
      this.$router.push({ name: 'FlowEdit', params: { id } });
    },
    clone(id) {
      console.warn(`Duplicar ${id}`);
    },
    deleteAll() {
      if (!this.selectedFlows.length) return;

      Swal.fire({
        title: 'Remover fluxos selecionados',
        text: 'Tem certeza que gostaria de remover os fluxos?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('generic-str.yes'),
      }).then((result) => {
        if (result.value) {
          const toDelete = [];
          this.selectedFlows.forEach((item) => {
            toDelete.push(item.id);
          });

          webApi.post('flows/delete-all', { flows: this.selectedFlows }).then(
            (response) => {
              this.fetch(this.form.page);
              Swal.fire(
                `${this.$t('generic-str.deleted')}!`,
                'Seus fluxos foram excluídos.',
                'success',
              );
              this.selectedFlows = [];
            },
            (error) => {
              this.$toast.show({
                title: `${this.$t('sms.infos.filters.status.lbl-error')} ${error.code}`,
                content: error.response.data.message,
                type: 'danger',
              });
            },
          );
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.main-search {
  margin: 0 0 2rem 0;

  .text-field {
    position: relative;
    margin: 0 auto;
    max-width: 952px;
    background: #fff;
    border: thin solid #ccc;
    border-radius: 0.2rem;
    width: 100%;

    input {
      background: transparent;
      padding-left: 2rem;
      padding-right: 2rem;
      width: 100%;
      height: 48px;
      border: none;
    }

    .material-icons-outlined {
      position: absolute;
      right: 8px;
      top: 8px;
      font-size: 2rem;
      pointer-events: none;
    }
  }
}
.flex-c {
  flex-direction: column;
}
.align-r {
  align-self: flex-end;
}
.trigger-type-button {
  display: flex;
  width: 100%;
  padding: 0.625rem;
  flex-direction: row;
  align-items: flex-start;
  border-radius: 0.5rem;
  border: 1px dashed #e5e5e5;
}
.trigger-type-button-channel {
  border: 1px solid #e5e5e5;
}
.trigger-type-description {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.trigger-type-description .trigger-type-titles h3 {
  color: #595959;
  font-size: 0.875rem;
  font-family: Poppins;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.trigger-type-description-channel .trigger-type-titles h3 {
  color: #595959;
  font-size: 0.875rem;
  font-family: Poppins;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.trigger-type-titles-item {
  font-size: 0.875rem !important;
  margin-top: 10px;
  margin-left: 10px;
}
.trigger-type-description-channel .trigger-type-titles p {
  color: #aaa;
  font-size: 0.75rem;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 0px;
}
.flow-card-title-info {
  color: var(--gray-font-color-3);
  margin-left: 5px;
}
.trigger-type-description .trigger-type-titles p {
  color: #aaa;
  font-size: 0.75rem;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 0px;
}
.trigger-type-description-channel {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 75%;
}
.trigger-type-description-channel-item {
  width: 90%;
}
.trigger-type-checkbox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 60px;
}
.trigger-type-icon {
  display: flex;
  width: 4rem;
  align-items: flex-start;
  gap: 0.625rem;
}
.trigger-type-icon-channel {
  display: flex;
  width: 3rem;
  align-items: flex-start;
  margin-left: 5px;
}
.trigger-type-icon-channel img {
  width: 60%;
  margin-top: 10px;
  width: 2.14006rem;
  height: 2.14006rem;
}
.form-wrapper {
  flex: 1 0 20%;
  .form-group {
    height: 55px;
  }
  .form-control,
  .input-group-prepend,
  .input-group,
  .icon_btn {
    height: 100%;
    span {
      color: var(--gray-font-color-5);
      font-size: 38px;
    }
  }
}
@media (min-width: 1660px) {
  .form-wrapper {
    min-width: 0;
    max-width: 100%;
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    flex-basis: 0;
    flex-grow: 1;
  }
}
@media (max-width: 767.98px) {
  .form-wrapper {
    flex: 1 0 40%;
  }
}
.form-group {
  label {
    color: var(--foreground);
    font-size: 16px;
    margin-bottom: 20px;
  }
  input:-ms-input-placeholder {
    text-transform: lowercase !important;
  }
  input::-webkit-input-placeholder {
    text-transform: lowercase !important;
  }
  input::placeholder {
    text-transform: lowercase !important;
  }

  #inputPassword {
    border-right-color: transparent;
    border-right-width: 0;
  }

  .icon_btn {
    display: flex;
    align-items: center;
    background-color: #fff;
    border: 1px solid var(--form-border);
    border-left-color: transparent;
    border-radius: 0.25rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

    span {
      color: var(--gray-font-color);
    }
  }
}
#close-dialog {
  position: absolute;
  top: 6px;
  right: 10px;
  cursor: pointer;
  transform: scale(1.25);
  padding: 10px;
  background: unset;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}
#close-dialog::after {
  content: "\e5cd";
  font-family: "Material Symbols Outlined";
  color: #6b23dc;
  transform: scale(1.6);
}
/* Rebranding */
.card.card-tabs .card-header .nav.nav-tabs {
  width: 100%;
  padding: 0 1rem;
  gap: 2.5rem;
}
.card.card-tabs .card-header .nav.nav-tabs li a {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 1.4rem 0.7rem 1.3rem;
  color: var(--clr-light-2);
}
.nav.nav-tabs .nav-item .nav-link.router-link-exact-active {
  color: var(--clr-yup-purple) !important;
  border-bottom: solid 2px var(--clr-yup-purple);
}

.nav.nav-tabs .nav-item .nav-link.router-link-exact-active i {
  color: var(--clr-yup-purple) !important;
}

.card.card-tabs .card-header .nav.nav-tabs li a i {
  font-size: 24px;
}

.nav-tabs .nav-item {
  margin-bottom: -2px;
}
.nav-tabs {
  border-bottom: 2px solid rgba(210, 221, 234, 0.5);
}

.custom-control-label {
  &::after,
  &::before {
    top: -0.5rem;
  }
}
.btn i {
  line-height: 1;
  color: inherit;
}
.btn {
  margin-bottom: 5px;
}
.btn-search {
  position: absolute;
  right: 0;
  top: 0;
}
.type-flow-item {
  border-radius: 6px;
  padding: 10px;
  cursor: pointer;
  margin-bottom: 5px;
  display: block;
}
.type-flow-item:hover {
  background: #752de6;
  color: #fff;
}
.type-flow-item:hover p {
  color: #fff;
}
.type-flow-item:hover i {
  color: #fff;
}
.type-flow-item-active {
  background: #752de6;
  color: #fff;
}
.type-flow-item-active p {
  color: #fff;
}
.type-flow-item-active i {
  color: #fff;
}
.flow-trigger-icon {
  font-size: 25px;
  cursor: pointer;
}
.color-white {
  color: #fff !important;
}
.m-left-5 {
  margin-left: 5px !important;
}
.flow-channel-item {
  border: solid 1px #ecedee;
  box-shadow: 0px 0px 12px #752de629;
  width: 57px;
  height: 57px;
  border-radius: 4px;
  padding: 10px;
  float: left;
}
.flow-channel-item-line {
  width: 80px;
  height: 0px;
  border: 1px solid #9ca7b0;
  float: left;
  margin-top: 25px;
  margin-left: 5px;
  margin-right: 5px;
}
.element-flow-item-card {
  margin: 0 auto;
  width: 209px;
  margin-bottom: 45px;
  margin-top: 60px;
}
.element-flow-item-title {
  color: #0e1421;
  font-weight: 600;
  margin-left: 10px;
  float: left;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 200px;
  height: 1.2em;
  white-space: nowrap;
}
.refresh-date-item {
  color: #9ca7b0;
  font-size: 10px;
  position: absolute;
  right: 15px;
  bottom: 15px;
}
.flow-channel-item-check {
  float: left;
}
.flow-channel-item-menu {
  float: right;
}
.flow-channel-item-menu img {
  height: 25px;
}
.flow-channel-item-menu {
  cursor: pointer !important;
}
.btn-new-flow {
  background: #752de6 !important;
  border: solid 1px #752de6 !important;
}
.btn-new-flow:hover {
  background: #8238f9 !important;
}
.tab-flow ul li.active {
  color: #8238f9 !important;
}
.tab-flow {
  padding: 30px 30px 0px 0px;
  border-bottom: solid 1px #0000001a;
  width: 100%;
  margin-bottom: 20px;
}
.tab-flow ul {
  list-style: none;
  margin: 0px;
  padding-left: 15px;
  position: relative;
}
.tab-flow ul li {
  color: #423c4c;
  padding-bottom: 50px;
  float: left;
}
.tab-flow ul li {
  width: 180px;
  padding-left: 30px;
  cursor: pointer;
}
.tab-flow ul .tab-indicator {
  width: 180px;
  content: "";
  border-bottom: 2px solid #752de6;
  display: inline-block;
  position: absolute;
  bottom: -80px;
  left: 10px;
  transition: left 0.5s;
}
.tab-flow ul li span {
  color: #423c4c;
  float: left;
  font-size: 28px !important;
}
.tab-flow ul li strong {
  color: #423c4c;
  float: left;
  font-size: 18px;
  font-weight: 200;
}

.tab-flow ul li:hover strong {
  color: #8238f9 !important;
}
.tab-flow ul li:hover span {
  color: #8238f9 !important;
}
.tab-flow ul li.active strong {
  color: #8238f9 !important;
}
.tab-flow ul li.active span {
  color: #8238f9 !important;
}
.title-new-flow {
  font-weight: 100;
}
.flow-channel-item-modal {
  border: solid 1px #ecedee;
  box-shadow: 0px 0px 12px #752de629;
  border-radius: 4px;
  padding: 10px;
  width: 100%;
}
.flow-channel-item-modal img {
  width: 30% !important;
}
.top2 {
  margin-top: 2px !important;
}
.left15 {
  margin-left: 15px !important;
}
@media (max-width: 666px) {
  .tab-indicator {
    display: none !important;
  }
}
.no-item-flow span {
  font-size: 70px !important;
  color: #ccc;
}
.no-item-flow h3 {
  color: #ccc;
}
.no-item-flow {
  margin-top: 20px;
  margin-bottom: 20px;
}

.all-check-box {
  .check-all-info {
    color: var(--foreground);
  }
}
.container-add,
.container-catalogs {
  max-width: 1740px;
}
.card-add-list {
  background: #edf6ff;
  overflow: hidden;
  position: relative;
  height: 264px;
  border-radius: 8px;
  margin-bottom: 4rem;
  box-shadow: 0px 3px 36px 6px #d4e3ff59 !important;

  > .card-body {
    display: flex;
  }

  .card-text {
    color: #0d0519;
    font-size: 24px;
    position: relative;
    z-index: 1;
  }

  .img-wrapper {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 437px;
    height: 437px;
    background-color: #a941fa;
    border-radius: 50%;
    z-index: 0;
  }

  .star-1-flow {
    position: absolute;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 437px;
    height: 120%;
    border-radius: 0;
    z-index: 0;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .star-2-flow {
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    height: 300px;
    width: 300px;
    text-align: center;
    padding-top: 50px;
  }

  .btn-add {
    display: flex;
    align-items: center;
    justify-content: center;
    width: min-content;
    background-color: #6b23dc;
    color: #fff;
    font-weight: 400;
    border-radius: 8px;
    font-size: 1rem;
  }
  .btn-add-template {
    display: flex;
    align-items: center;
    justify-content: center;
    width: min-content;
    background-color: none;
    color: #6b23dc;
    font-weight: 400;
    border-radius: 8px;
    font-size: 1rem;
    margin-left: 10px;
    border: solid #6b23dc 1px;
  }
}
.title-list {
  color: var(--gray-font-color-5);
  font-weight: 400;
  display: block;
  padding: 8px 20px;
}
.title-list-icon {
  font-weight: 200;
  font-size: 24px;
  padding-top: 10px;
  float: left;
}
.title-list-text {
  font-weight: 200;
  margin-top: 10px;
  display: block;
  float: left;
}
.options-cat {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-left: auto;
  gap: 10px;
  li {
    color: var(--gray-font-color-5);
  }
}
.btn.btn-add-list {
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent !important;
  border: unset !important;
  font-size: 1rem;

  &:hover,
  &:focus {
    color: var(--clr-yup-purple-hover) !important;
    span {
      border-color: var(--clr-yup-purple-hover);
    }
  }

  span {
    margin-left: 5px;
    border: 1px solid var(--clr-yup-purple);
    border-radius: 50%;
    font-size: 18px;
    font-weight: 400;
  }
}
hr.purple {
  width: 98%;
  border-top: 5px solid #6b23dc3d;
  border-radius: 75px;
}
.btn-add {
  display: flex;
  align-items: center;
  justify-content: center;
  width: min-content;
  background-color: #6b23dc;
  color: #fff;
  font-weight: 400;
  border-radius: 8px;
  font-size: 1rem;
}
.card-flow-template {
  background: #fff;
  border: solid 1px #dfdfdf;
  border-radius: 10px;
  margin-bottom: 10px;
}
.title-list-line {
  height: 5px;
  background: #6b23dc;
  width: 130px;
  position: absolute;
  bottom: 17px;
  border-radius: 10px;
  right: 0;
  left: 1%;
  transition: left 0.3s;
}
.c-pointer {
  cursor: pointer !important;
}
.title-list:hover {
  color: #6b23dc;
}
.trigger-flow-active-icon {
  position: absolute;
  right: -10px;
  top: -10px;
}
.trigger-flow-active {
  border: solid 1px #6b23dc !important;
}
.trigger-flow-active h3 {
  color: #6b23dc !important;
}
.trigger-flow-active p {
  color: #6b23dc !important;
}
::v-deep {
  .dropdown .dropdown-menu.dropdown-menu-right {
    right: auto !important;
    left: 0 !important;
  }
  .dropdown-item {
    display: flex !important;
    align-items: center !important;
    gap: 10px;
    color: var(--gray-font-color-5);
  }
  .pagination {
    justify-content: end;
  }
}
::v-deep #sidebar-right-flow {
  background-color: var(--background-2) !important;
  width: 525px;
  small {
    color: var(--gray-font-color);
  }
  // Default sidebar header
  .b-sidebar-header {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    button.close {
      margin-left: auto;
      margin-right: unset;
    }
  }
  // Custom sidebar header
  .sidebar-header {
    display: flex;
    align-items: center;
    padding-top: 2.5rem;
    p {
      font-weight: 500;
      font-size: 1.25rem;
    }
    span {
      color: var(--gray-font-color-5);
    }
    span.sidebar-close {
      position: absolute;
      top: 12px;
      right: 0;
    }
    .arrow-back {
      position: absolute;
      top: 12px;
      left: -10px;
    }
  }
  .b-sidebar-body {
    padding: 0 1.5rem;
  }
  header {
    > strong {
      order: -1;
    }
  }
  .desc-column {
    p {
      color: var(--gray-font-color-4);
    }
  }
}
.add-prod-form {
  .input-group {
    margin-bottom: 2rem;
  }

  label {
    display: flex;
    gap: 5px;
    width: 100%;
    margin-bottom: 0.725rem;
    font-size: 1rem !important;

    span {
      color: var(--gray-font-color-3);
    }
  }
}
.border-radius-5 {
  border-radius: 0.5rem !important;
}
.trigger-type {
  padding: 0.625rem;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  border-radius: 0.25rem;
  background: #f2f2f2;
}
.trigger-type h3 {
  color: #0d0519;
  text-align: center;
  /* H2/Poppins 16px w500 */
  font-size: 1rem;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  float: left;
  margin: 0px;
  margin-top: 0px;
  margin-right: 0px;
  margin-top: 3px;
  margin-right: 10px;
}
.info-channel {
  color: #0d0519;
  text-align: center;
  font-size: 1rem;
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  line-height: 1.875rem;
}
.trigger-type span {
  color: #aaaaaa;
}
@media screen and (max-width: 1000px) {
  .card-add-list .card-text {
    font-size: 15px !important;
  }
}
@media screen and (max-width: 860px) {
  .card-add-list .card-text {
    font-size: 15px !important;
  }
}
@media screen and (max-width: 800px) {
  .card-add-list {
    display: none !important;
  }
}
.span-type-flow {
  position: absolute;
  top: 25%;
  font-size: 13px;
  background: #e8e8e8;
  padding: 5px;
  padding-right: 5px;
  padding-left: 5px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 8px;
}
.form-wrapper {
  flex: 1 0 20%;
  .form-group {
    height: 55px;
  }
  .form-control,
  .input-group-prepend,
  .input-group,
  .icon_btn {
    height: 100%;
    span {
      color: var(--gray-font-color-5);
      font-size: 38px;
    }
  }
}
.darkmode .rebranding .form-group .form-control,
.rebranding .form-group .form-control {
  border-right: unset !important;
}
.form-group .icon_btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #fff;
  border: 1px solid var(--form-border);
  border-left-color: transparent;
  border-radius: 0.25rem;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.darkmode .form-group .icon_btn {
  background-color: var(--form-input);
  border-color: var(--form-border);
  border-left-color: transparent;
}
.icon-no-item {
  display: block;
  font-size: 60px;
  color: #8f8f8f;
  margin-bottom: 10px;
}
</style>
